/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CrudService } from './crud.service';
import { NetworkDebugService } from './debug.service';
import { PsiReplaySubject, SSE } from './sse';
import { ChannelAction, ChannelOptions, SocketIOWrapper } from './socketio';


@Injectable({
  providedIn: 'root'
})
export abstract class PushService<T> extends CrudService<T> {
  private sseSvc = inject(SSE);
  private socketIOSvc = inject(SocketIOWrapper);

  constructor(path: string) {
    super(path);
  }

  unsubscribeComponent(eventsId: string) {
    // questa funzione sarà chiamata dopo l'ultima unsubscribe() su un determinato Observable
    // ed avvisa il server che le notifiche SSE relative a questa subscription non ci servono più
    this.sseSvc.unsubscribe(eventsId);
    this.sseSvc.forgetSubscription(eventsId);
  }

  getSSE(url: string, componentId: string): Observable<T[]> {
    const eventsId = uuidv4();
    console.log("Creo nuova sottoscrizione SSE per " + componentId + " con ID " + eventsId);
    const subj = new PsiReplaySubject<T[]>(1, () => {
      this.unsubscribeComponent(eventsId);
    });
    this.sseSvc.checkConnection().then((_v: boolean) => {
      this.sseSvc.subscribe(url, eventsId, subj, componentId).then((ok: boolean) => {
        if (ok)
          console.log("Attivata sottoscrizione per " + componentId + " su " + url + " con ID " + eventsId);
      });
    });
    return subj;
  }

  getIO(url: string, chOpt: ChannelOptions): Observable<ChannelAction<T>[]> {
    const subj = new PsiReplaySubject<ChannelAction<T>[]>(1, () => {
      console.log("TODO: annullare l'ascolto sull'evento di socket.io");
    });
    this.socketIOSvc.subscribe(url, chOpt, subj);
    return subj;
  }

  async onLogout() {
    await this.sseSvc.unsubscribeAll();
    this.sseSvc.forgetAllSubscriptions();
    this.sseSvc.closeConnection();
  }

  debugService(): NetworkDebugService {
    return this.sseSvc.dbgSvc;
  }
}
