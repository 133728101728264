/* eslint-disable @typescript-eslint/naming-convention */
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PushService } from './push.service';
import { AuthService } from './auth.service';
import { CRUD_BASE, LOGIC_BASE } from './endpoints';

export interface IdSSO {
  idsso: string;
}
export interface Utente extends IdSSO {
  id?: string;
  nome?: string;
  cognome?: string;
  cf?: string;
  data_nascita?: string;
  telefono?: string;
  cellulare?: string;
  email?: string;
  indirizzo?: string;
  numero_civico?: string;
  CAP?: string;
  comune?: string;
  provincia?: string;
  note?: string;
  descrizione?: string;
  principale?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class UtentiService extends PushService<Utente>{

  private static profilesUrlKey = 'profilesUrl';
  private utentiUrl = CRUD_BASE + '/utente';

  constructor() {
    super('utente');
  }

  getUtenti(): Observable<Utente[]> {
    return this.hget<Utente[]>(this.getProfilesUrl());
  }

  getUtente(id: string): Observable<Utente> {
    return this.hget<Utente>(`${this.getProfilesUrl()}/${id}`);
  }

  getUtenteByCf(cf: string): Observable<string[]> {
    return this.hget<string[]>(LOGIC_BASE + `/utenti/byCF/${cf}`);
  }

  createUtente(body: IdSSO): Observable<string> {
    // KNOWN BUG: in linea teorica una webapp modificata malignamente potrebbe creare
    // un nuovo profilo tutte le volte che vuole, perché qui chiamiamo direttamente il Crud,
    // il quale l'unica cosa che può fare è verificare che l'utente sia autenticato, ma non
    // può verificare che l'idSSO passato come parametro coincida con l'utente loggato
    // e non può impedire al cittadino di creare il proprio profilo al primo login:
    // il Crud deve fare il Crud, mentre quelle validazioni sono di livello superiore e, se
    // necessarie, devono essere implementate nel Logic.
    // Quello che servirebbe è un nuovo endpoint nel Logic che faccia tale verifica: in presenza
    // di quell'endpoint potremmo limitare l'uso del Crud ai ruoli agenda e configuratore,
    // impedendo al cittadino di fare porcherie direttamente sul Crud.
    // Tutto ciò richiederebbe, anche qui nella webapp, due chiamate differenti, a seconda
    // che il modulo Angular che necessita di un nuovo profilo sia nell'interfaccia per il
    // cittadino o in quella per l'agenda/configuratore.
    // Per il momento lasciamo tutto così (e nel Crud lasciamo l'endpoint accessibile anche
    // ai cittadini), tenuto conto che la modifica maligna della webapp è una cosa complicata
    // e che il massimo che si ottiene è la creazione di profili inutili, quindi il massimo a cui
    // il giovane hacker rompizebedei può aspirare è di sporcare il database e forse
    // ottenere un effeto DoS o DDoS, ma i dati degli altri utenti legittimi restano comunque al
    // sicuro e un effetto DoS si può comunque ottere per altre vie più semplici di questa.
    // Tutto ciò è documentato in PRECL-197: https://teom.atlassian.net/browse/PRECL-197
    return this.hpost<string>(`${this.getProfilesUrl()}`, body).pipe(map((idUtente: string) => {
      this.hpost<string>(`${LOGIC_BASE}/auth/creaIdUtente/${idUtente}/${body.idsso}`, body);
      return idUtente;
    }));
  }

  updateUtente(id: string, body: object): Observable<string> {
    const rbody: Utente = {
      idsso: '',
      id: ''
    };
    Object.assign(rbody, body);
    delete rbody.updatedAt;
    delete rbody.deletedAt;
    delete rbody.createdAt;
    delete rbody.id;
    return this.hput<string>(`${this.getProfilesUrl()}/${id}`, rbody);
  }

  getUtenteByIdSSO(idsso: string): Observable<Utente[]> {
    return this.hget<Utente[]>(`${this.utentiUrl}/sso/${idsso}`);
  }

  setProfilesDomain(profilesDomain: string) {
    const profilesUrl = profilesDomain ? 'https://' + profilesDomain + this.utentiUrl : this.utentiUrl;
    sessionStorage.setItem(UtentiService.profilesUrlKey, profilesUrl);
  }

  getProfilesUrl(): string {
    return sessionStorage.getItem(UtentiService.profilesUrlKey);
  }

  deleteUtente(id: string): Observable<string> {
    return this.hdelete<string>(`${this.utentiUrl}/${id}`);
  }

  getIdUtenteAgenda(): Observable<string> {
    return this.hget<string>(LOGIC_BASE + '/utenti/idUtenteAgenda/');
  }

  getIdUtenteTotem(): Observable<string> {
    return this.hget<string>(LOGIC_BASE + '/utenti/idUtenteTotem/');
  }

  listaRuoli(): Observable<string[]> {
    return this.hget<string[]>(LOGIC_BASE + '/auth/listaRuoli/');
  }

}
