/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './baseservice';
import { LOGIC_BASE } from './endpoints';

@Injectable({
  providedIn: 'root',
})
export class NetworkDebugService extends BaseService<any> {
  private problemiConnessione = new Subject<string>();

  getProblemiConnessione(): Observable<string> {
    return this.problemiConnessione;
  }

  segnalaConnessioneOk() {
    this.problemiConnessione.next("");
  }

  segnalaConnessioneKo() {
    this.problemiConnessione.next("Connessione in corso, attendere...");
  }
}
