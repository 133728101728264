import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io, Socket } from "socket.io-client";
import { BaseService } from './baseservice';
import { Entity } from './crud.service';
import { NetworkDebugService } from './debug.service';

interface PsiUnsubscribeNotifiable {
  notifyUnsubscribed(): void;
}

export const enum ACTION {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted'
}

export const enum ChannelMode {
  MANAGED_ARRAY,
  UPDATES_ONLY,
}
export interface ChannelOptions {
  action?: ACTION,
  channel?: string,
  mode?: ChannelMode
}
export interface ChannelAction<T=any> extends ChannelOptions {
  payload?: T,
  id?: string
}


interface SubscribedEndpoint {
  subscriptionId: string,
  endpoint: string
}

export interface UrlAndSubject<T> {
  url: string,
  sseUrl: string,
  subject: Subject<T>,
  componentId: string
}

interface ObservablesMap {
  [eventsId: string]: UrlAndSubject<any>;
}
interface WatchdogCallback {
  delayFactor: number;
  fn: () => any;
}

interface Watchdogs {
  [wdid: string]: WatchdogCallback;
}

interface WatchdogTimers {
  [wdid: string]: any; // risultato di setTimeout
}

@Injectable({
  providedIn: 'root'
})
export class SocketIOWrapper {

  private baseSvc = inject(BaseService);
  public dbgSvc = inject(NetworkDebugService);

  public connection: Socket;
  public abortController: AbortController = undefined;
  public observables: ObservablesMap = {};

  private bearerTokenTimer: any;

  constructor() {
  }

  openConnection(): void {
    if (!this.connection) {
      const bt = this.baseSvc.addBearerToken();
      this.connection = io({
        extraHeaders: bt.headers,
      }).on("connect", () => {
        console.log("SocketIO client connesso");
        this.dbgSvc.segnalaConnessioneOk();
        if (this.bearerTokenTimer)
          clearInterval(this.bearerTokenTimer);
        this.bearerTokenTimer = setInterval(() => {
          this.connection.io.opts.extraHeaders = this.baseSvc.addBearerToken().headers;
        }, 600000); // aggiorno il bearer token ogni 10 minuti
      });
      this.connection.on("connect_error", (_err) => {
        // the reason of the error, for example "xhr poll error"
        console.log('ERRORE CONNESSIONE');
        this.dbgSvc.segnalaConnessioneKo();
        if (this.bearerTokenTimer)
          clearInterval(this.bearerTokenTimer);
        this.bearerTokenTimer = setInterval(() => {
          this.connection.io.opts.extraHeaders = this.baseSvc.addBearerToken().headers;
        }, 2000); // tento di aggiornare il bearer token ogni 2 secondi
      });
    }
  }

  public closeConnection() {
    if (this.connection)
      this.connection.close();
    this.connection = undefined;
  }

  async checkConnection(): Promise<any> {
    if (typeof this.connection === 'undefined') {
      console.log("Manca la connessione SocketIO, la faccio ora");
      this.closeConnection();
      return this.openConnection();
    }
  }

  subscribe<T extends Entity>(url: string, chOpt: ChannelOptions, subject: Subject<ChannelAction<T>[]>): void {
    this.checkConnection();
    const tempSub = this.baseSvc.hget<T[]>(url).subscribe((primiRisultati: any) => {
      tempSub.unsubscribe();
      let risultati = primiRisultati as T[];
      let risultati_ca: ChannelAction<T>[] = risultati.map((r: T) => { return { action: ACTION.CREATED, payload: r, id: r.id }; })
      subject.next(risultati_ca);
      const subscriber = (iodata: any): void => {
        try {
          if (iodata) {
            const data = iodata as ChannelAction;
            if (chOpt.mode == ChannelMode.MANAGED_ARRAY) { // in questa modalità gestisco internamente l'array dei risultati
              switch(data.action) {
                case ACTION.CREATED:
                  risultati_ca.push(data);
                  break;
                case ACTION.UPDATED:
                  risultati_ca = risultati_ca.map((e: ChannelAction<T>) => e.id === data.id ? data : e);
                  break;
                case ACTION.DELETED:
                  risultati_ca = risultati_ca.filter((e: ChannelAction<T>) => e.id != data.id);
                  break;
              }
              subject.next(risultati_ca);
            } else { // in questa modalità mando al chiamante solo l'oggetto della notifica di Socket IO e a rimettere a posto l'array ci pensa lui
              subject.next([data]);
            }
          }
        } catch (err: any) {
          console.error(err);
        }
      };
      if (chOpt.action)
        this.connection.on(chOpt.channel + chOpt.action, subscriber);
      else
        this.connection.on(chOpt.channel, subscriber);
    });
  }

  public unsubscribe(eventsId: string) {
    if (this.connection) {
      this.connection.off(eventsId);
    }
  }

}

